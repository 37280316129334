import React, { useEffect, useState } from 'react';
import './FadeIn.css'; // Create a corresponding CSS file

const FadeInWrapper = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., loading resources)
    const asyncOperation = async () => {
      // Simulate loading time (you can replace this with actual loading logic)
      await new Promise((resolve) => setTimeout(resolve, 250));

      // Set isLoaded to true after loading is done
      setIsLoaded(true);
    };

    asyncOperation();
  }, []);

  return (
    <div className={`fade-in ${isLoaded ? 'loaded' : ''}`}>
      {children}
    </div>
  );
};

export default FadeInWrapper;
