import React from 'react';

import './App.css';
import MainPage from './MainPage';
import FadeInWrapper from './components/FadeIn';


function App() {
  
  const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

  return (
    <div className="App">
      <FadeInWrapper>
        <MainPage isMobile={isMobile} />
      </FadeInWrapper>
    </div>
  );
}

export default App;
