import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import PillButton from './components/PillButton';

const iconStyle = {
    width: "4rem",
    padding: 1,
}

const skillStyle = {
    margin: 10,
}

const downloadIconStyle = {
    width: "1.5rem",
    padding: 1,
}

const firstImageStyle = {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
}

const imageStyle = {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 400,
    height: 400,
};

const MainPage = ({ isMobile }) => {

    return ( 
        <Fragment>
        <br/>
        <Container  fluid>
            <Row>
                <Col>
                    <h1 style={{ fontSize: isMobile ? "2rem" : "3rem"  }} id="title-name">Beverly Green</h1>
                </Col>
            </Row>
        </Container>
        </Fragment>
    );
}
 
export default MainPage;